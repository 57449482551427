import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Branch } from './branches/branch.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BranchService {
  //private apiUrl = 'https://starappstechnologies.com/fel/auth/public/api/clients-by-company/3'; // Replace with your actual API endpoint
  //private apiUrl = 'http://localhost/fel/auth/public/api/login?email=facturaelectronicaintouch@gmail.com&password=123456';
  private apiUrlGet = environment.apiHost+'branches-by-company/';
  private apiUrlPost = environment.apiHost+'branches';

  constructor(private http: HttpClient) {}

  getBranches(companyId:string): Observable<Branch[]> {
    return this.http.get<Branch[]>(this.apiUrlGet+companyId);
  }

  postOneBranch(companies_id:string,internalName: string, name: string, email:string, phone:string): Observable<any> {
    const body = { companies_id:companies_id,departments_id:5,internal_name:internalName,name:name,email:email,phone:phone };
    console.log('branch-service.ts')
    console.log(this.apiUrlPost)
    return this.http.post<any>(this.apiUrlPost, body);
  }

  markAsOpen(id:any,string1:string,string2:string){
    const body = {id:id,string1:string1,string2:string2};
    
    return this.http.post<any>(environment.apiHost+'mark-as-open', body);
    //return this.http.get<Branch[]>('http://localhost/scheduler-php/public/api/mark-as-open/'+id);
  }
  
  markAsClosed(id:any,string1:string,string2:string){
    const body = {id:id,string1:string1,string2:string2};
    console.log('branch-service.ts line 35')
    console.log('string1 '+string1)
    console.log('string2 '+string2)
    console.log('branch-service.ts line 35')
    console.log(environment.apiHost+'mark-as-closed  BODY:'+body )
    return this.http.post<any>(environment.apiHost+'mark-as-closed', body);
    //return this.http.get<Branch[]>('http://localhost/scheduler-php/public/api/mark-as-open/'+id);
  }

  getOneBranch(id:any): Observable<Branch[]> {
    return this.http.get<Branch[]>(environment.apiHost+'branch/'+id);
  }

  /*
  markAsClosed(id:any){
    return this.http.get<Branch[]>('http://localhost/scheduler-php/public/api/mark-as-closed/'+id);
  }
  */
}
