<header>
  <mat-toolbar style="background-color: #71639e;">
    <mat-toolbar-row>
      &nbsp;&nbsp;&nbsp;&nbsp;
      <button mat-mini-fab color="accent" aria-label="Example icon button with a menu icon" title="Ir al inicio" style="background-color: #b332af;"  (click)="mainActivityVisibility=true">
        <mat-icon>menu</mat-icon>
      </button>
      <div style="color: #FFFFFF;"> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; SEGUREX APLICATION</div>
    </mat-toolbar-row>
  </mat-toolbar>
</header>
<br><br>
<div *ngIf="mainActivityVisibility">
    <header>
      <mat-toolbar (click)="mainActivityVisibility=false">
        <mat-toolbar-row style="padding:50px;">
          &nbsp;&nbsp;&nbsp;&nbsp;
          <button mat-mini-fab color="accent" aria-label="Example icon button with a menu icon" title="Policia"  (click)="openPoliceChannel()">
            <mat-icon>
              <span class="material-symbols-outlined">
                local_police
                </span>
            </mat-icon>
          </button>
          <div>&nbsp;&nbsp;&nbsp;&nbsp; Policia</div>
        </mat-toolbar-row>
      </mat-toolbar>
    </header>
    <br><br>
    <header>
      <mat-toolbar>
        <mat-toolbar-row style="padding:50px;">
          &nbsp;&nbsp;&nbsp;&nbsp;
          <button mat-mini-fab color="accent" aria-label="Example icon button with a menu icon" title="Ir al inicio" (click)="mainActivityVisibility=true">
            <mat-icon>medication</mat-icon>
          </button>
          <div>&nbsp;&nbsp;&nbsp;&nbsp; Ambulancia</div>
        </mat-toolbar-row>
      </mat-toolbar>
    </header>
    <br><br>
    <header>
      <mat-toolbar>
        <mat-toolbar-row style="padding:50px;">
          &nbsp;&nbsp;&nbsp;&nbsp;
          <button mat-mini-fab color="accent" aria-label="Example icon button with a menu icon" title="Ir al inicio" (click)="mainActivityVisibility=true">
            <mat-icon>
              <span class="material-symbols-outlined">
                local_fire_department
                </span>
            </mat-icon>
          </button>
          <div>&nbsp;&nbsp;&nbsp;&nbsp; Bomberos</div>
        </mat-toolbar-row>
      </mat-toolbar>
    </header>
    <br><br>
    <header>
      <mat-toolbar>
        <mat-toolbar-row style="padding:50px;">
          &nbsp;&nbsp;&nbsp;&nbsp;
          <button mat-mini-fab color="accent" aria-label="Example icon button with a menu icon" title="Ir al inicio" (click)="mainActivityVisibility=true">
            <mat-icon>
              <span class="material-symbols-outlined">
                warning
              </span>
            </mat-icon>
          </button>
          <div>&nbsp;&nbsp;&nbsp;&nbsp; Riesgo eléctrico</div>
        </mat-toolbar-row>
      </mat-toolbar>
    </header>
    <br><br>
    <header>
      <mat-toolbar>
        <mat-toolbar-row style="padding:50px;">
          &nbsp;&nbsp;&nbsp;&nbsp;
          <button mat-mini-fab color="accent" aria-label="Example icon button with a menu icon" title="Ir al inicio" (click)="mainActivityVisibility=true">
            <mat-icon>nature people</mat-icon>
          </button>
          <div>&nbsp;&nbsp;&nbsp;&nbsp; Guardia ambiental - Epa</div>
        </mat-toolbar-row>
      </mat-toolbar>
    </header>
    <br><br>
    <header>
      <mat-toolbar>
        <mat-toolbar-row style="padding:50px;">
          &nbsp;&nbsp;&nbsp;&nbsp;
          <button mat-mini-fab color="accent" aria-label="Example icon button with a menu icon" title="Ir al inicio" (click)="mainActivityVisibility=true">
            <mat-icon>groups</mat-icon>
          </button>
          <div>&nbsp;&nbsp;&nbsp;&nbsp; Vecinos</div>
        </mat-toolbar-row>
      </mat-toolbar>
    </header>
    <br><br>
</div>
<div *ngIf="!mainActivityVisibility" >
  <div style="background-color: #888888;">
    <br>
    <div style="width: 100%;text-align: center;color:#FFFFFF;"><h2 style="color:#FFFFFF;">Solicitando atención del cuadrante! </h2></div>
    <div style="width: 100%;text-align: center;">  <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/1/1e/Coat_of_arms_of_colombian_national_police.svg/1200px-Coat_of_arms_of_colombian_national_police.svg.png" alt="" width="150px">
    </div>
  </div>
  <br>
  <div style="width: 100%;text-align: center;font-size: 10px;">
      <b>Mi ubicación:</b> Barrio las gaviotas, calle 33 No. 49-66
  </div>
  <div style="width: 100%;text-align: center;"> 
      <span style="font-size:10px;">Emitiendo alerta...</span><br>
      <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRCtjaETRRhQ3OU-57b_GjNkrL0JBmxjXr7xZ7KBTaHv4l9Vo_fKbTvAAx7ngRQwflpMb0&usqp=CAU" alt="" width="80px"><br>
  </div>
  <br>
  <div style="width: 100%;text-align: center;">
    <h3>Respuesta policia nacional</h3>
    <p><b>Atendiento:</b> 2 unidades de policia</p>
    <p><b>Tiempo estimado de llegada:</b> 1 minuto</p>
  </div>
  <br>
  <br>
  <br>
  
  <div style="width: 100%; background-color: #888888;padding: 50px;">
    <table style="width: 100%;">
      <tr>
        <td>
          <button mat-mini-fab color="accent" aria-label="Example icon button with a menu icon" style="background-color: #0b46af;" title="Ir al inicio" (click)="mainActivityVisibility=true">
            <mat-icon>
              <span class="material-symbols-outlined">chat</span>
            </mat-icon>
          </button>
        </td>
        <td>
          <button mat-mini-fab color="accent" aria-label="Example icon button with a menu icon" style="background-color: #0b46af;" title="Ir al inicio" (click)="mainActivityVisibility=true">
            <mat-icon>
              <span class="material-symbols-outlined">
                photo_camera
                </span>
            </mat-icon>
          </button>
        </td>
        <td>
          <button mat-mini-fab color="accent" aria-label="Example icon button with a menu icon"  style="background-color: #0b46af;" title="Ir al inicio" (click)="mainActivityVisibility=true">
            <mat-icon>
              <span class="material-symbols-outlined">
                videocam
              </span>
            </mat-icon>
          </button>
        </td>
        <td>
          <button mat-mini-fab color="accent" aria-label="Example icon button with a menu icon" style="background-color: #0b46af;"  title="Ir al inicio" (click)="mainActivityVisibility=true">
            <mat-icon>
              <span class="material-symbols-outlined">
                add_link
                </span>
            </mat-icon>
          </button>
        </td>
      </tr>
    </table>
  </div>
</div>



<!--
<footer>
  
  <content>
    <div>
      <a target="_blank" rel="noopener" href="https://github.com/abraham/pwa-ng"
        >Angular PWA</a
      >
      is made by
      <a target="_blank" rel="noopener" href="https://twitter.com/abraham"
        >@abraham</a
      >
      to show how easy it is to build a
      <a
        target="_blank"
        rel="noopener"
        href="https://developers.google.com/web/progressive-web-apps/"
        >PWA</a
      >
      with
      <a target="_blank" rel="noopener" href="https://angular.io/">Angular</a>.
    </div>
    <div>
      Have a more complex app you need help taking offline?
      <a target="_blank" rel="noopener" href="https://bendyworks.com/"
        >Bendyworks</a
      >
      can help.
    </div>
    <div>
      Built with
      <a target="_blank" rel="noopener" href="https://angular.io/">Angular</a>,
      <a
        target="_blank"
        rel="noopener"
        href="https://firebase.google.com/products/hosting/"
        >Firebase Hosting</a
      >,
      <a
        target="_blank"
        rel="noopener"
        href="https://material.io/components/web/"
        >Material Components</a
      >,
      <a
        target="_blank"
        rel="noopener"
        href="https://www.npmjs.com/package/node-package"
        >node-package</a
      >,
      <a
        target="_blank"
        rel="noopener"
        href="https://www.npmjs.com/package/img-2"
        >img-2</a
      >, and
      <a
        target="_blank"
        rel="noopener"
        href="https://github.com/lgarron/clipboard-polyfill"
        >clipboard-polyfill</a
      >.
    </div>
  </content>

</footer>
  -->
