import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AreaHelper } from './area.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AreaService {
  private apiUrlGet = environment.apiHost+'get-specific-roles-by-company/';
  private apiUrl = environment.apiHost+'get-one-area/';
  //private apiUrlPost = 'http://localhost/scheduler-php/public/api/branches';

  constructor(private http: HttpClient) {}

  /*
  getAreas(branchId:string): Observable<AreaHelper[]> {
    return this.http.get<AreaHelper[]>(this.apiUrlGet+branchId);
  }
  */

  getOneArea(branchId:number,roleId:number): Observable<AreaHelper[]> {
     return this.http.get<AreaHelper[]>(this.apiUrl+branchId+'/'+roleId);
  }
  /*
  postOneBranch(companies_id:string,internalName: string, name: string, email:string, phone:string): Observable<any> {
    const body = { companies_id:companies_id,departments_id:5,internal_name:internalName,name:name,email:email,phone:phone };
    return this.http.post<any>(this.apiUrlPost, body);
  }
  */
  
}
