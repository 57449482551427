import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Shift } from './shift.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MyShiftService {

  private apiUrlGet = environment.apiHost+'get-shifts-by-company-branch-area/';
  private apiUrlPost = environment.apiHost+'shift';

  constructor(private http: HttpClient) {}

  //Si esto da problemas hay que hacer un post entonces y se le manda como JSON
  getShifts(branchId:string,areaId:string): Observable<Shift[]> {
    //console.log("getUrl")
    //console.log(this.apiUrlGet+companyId+'/'+branchId+'/'+areaId)
    return this.http.get<Shift[]>(this.apiUrlGet+branchId+'/'+areaId);
  }

  postOneShift(companies_id:string,branches_id:string,areas_id:string,roles_id:string,role_name:string,patientsName:string,patientsIdentification:string): Observable<any> {
    const body = { companies_id:companies_id,branches_id:branches_id,roles_id:roles_id,areas_id:areas_id,role_name:role_name,patient_name:patientsName,patient_identification:patientsIdentification};
    console.log('body')
    console.log(body)
    return this.http.post<any>(this.apiUrlPost, body);
  }

  markOneAsDispatched(id:number,workstationsId:any){
    const body = {id:id,workstations_id:workstationsId};
    return this.http.post<any>(environment.apiHost+'mark-shift-as-dispatched', body);
  }

}
