<h4>Agregar Sucursal:</h4>
<form class="example-form">
    <mat-form-field class="example-full-width" appearance="fill">
    <mat-label>Nombre interno</mat-label>
    <input matInput placeholder="" name="internalName"
    [(ngModel)] ="internalName">
    </mat-form-field>
    <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>Nombre para mostrar</mat-label>
        <input matInput placeholder="" name="name"
        [(ngModel)] ="name">
    </mat-form-field>
    <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>Correo Electrónico</mat-label>
        <input matInput placeholder="Enter email" name="email"
        [(ngModel)] ="email">
    </mat-form-field>
    <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>Phone</mat-label>
        <input matInput placeholder="Enter phone" name="phone"
        [(ngModel)] ="phone">
    </mat-form-field>
    <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>Dirección</mat-label>
        <input matInput placeholder="" name="location"
        [(ngModel)] ="location">
    </mat-form-field>

    <!--
    <mat-form-field class="example-full-width" appearance="fill">
    <mat-label>Contraseña</mat-label>
    <input matInput placeholder="Enter your password" value="" name="password" type="password" [(ngModel)]= "password">
    </mat-form-field>
    -->

    <button mat-raised-button color="primary" (click)="submit()">Crear Sucursal</button>
    <!--<span *ngIf="show"> <button mat-button color="accent">Login success !!</button></span> -->
</form>

