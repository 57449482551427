<!--
&nbsp;&nbsp;&nbsp;&nbsp;
<mat-form-field class="example-full-width" appearance="fill">
    <mat-label>Sucursal</mat-label>
    <input matInput placeholder="" name="email" [(ngModel)] ="searcher">
</mat-form-field>
&nbsp;&nbsp;
<mat-icon aria-hidden="false" aria-label="Example home icon" fontIcon="search" (click)="filtrar()"></mat-icon>
-->
<ul>
<li *ngFor="let branch of branchesFiltered">
    <hr>
    <p (click)="seleccionar(branch.id)">{{ branch.name }}</p>
    <p (click)="seleccionar(branch.id)">{{ branch.location }}</p>
</li>
</ul>
