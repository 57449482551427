import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { routes } from './app.routes';
import { CommonModule } from '@angular/common';  
import { OnInit } from '@angular/core';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormsModule} from '@angular/forms';
import { MyShiftService } from './my-shift.service';
import { BranchService } from './branch.service';
import { AreaService } from './area.service';
import { CompanyService } from './company.service';
import { User } from './user.interface';
import { Company } from './company.interface';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AreaHelper } from './area.interface';
import { Branch } from './branches/branch.interface';
import { Shift } from './shift.interface';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule } from '@angular/material/menu';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { LoginService } from './login.service';
import { UserService } from './user.service';
import { LoginResponse } from './login-response.interface';
import { DialogElementsExampleDialogComponent } from './dialog-elements-example-dialog/dialog-elements-example-dialog.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  //imports: [FormsModule, MatFormFieldModule, MatInputModule,CommonModule]
})
export class AppComponent implements OnInit{
  //segurex
  users: User[] = [];
  companies: Company[] = [];
  shifts: Shift[] = [];
  companiesFiltered: Company[] = [];
  name:string="";
  internal_name:string="";
  searcher:string="";
  hash:string="";
  branchesFiltered: Branch[] = [];
  areasFiltered: AreaHelper[] = [];
  mainActivityVisibility=true;
  branchVisibility=false
  shiftListVisibility=true
  areaVisibility=false
  requestUserDataVisibility=true
  patientsName:string=""
  patientsIdentification:string=""
  myOwnShiftArea:any
  myOwnShift:any
  turnosAdelante:any
  empresaSetted=false
  sucursalSetted=false
  areaSetted=false
  companyName:string=""
  branchName:string=""
  branchLocation:string=""
  areaName:string=""
  loginvisibility:boolean=true
  username : string ="facturaelectronicaintouch@gmail.com";
  password : string ="123456";
  show: boolean= false;
  policeActivityVisibility=false;

  isChecked:boolean=false;
  
  constructor(
    private myShiftService: MyShiftService,
    private companyService: CompanyService,
    private branchService: BranchService, 
    private areaService: AreaService,
    private formsModule:FormsModule,
    private FlexLayoutModule:FlexLayoutModule,
    private matButtonModule:MatButtonModule,
    private matMenuModule:MatMenuModule,
    private matSnackBarModule:MatSnackBarModule,
    private matTabsModule:MatTabsModule,
    private matToolbarModule:MatToolbarModule,
    private matFormFieldModule:MatFormFieldModule,
    private matInputModule:MatInputModule,
    private http:HttpClient,
    private loginService: LoginService,
    private userService:UserService,
    public dialogElementsExampleDialogComponent:DialogElementsExampleDialogComponent,
    public dialog:MatDialog,
    ) {}
    
    openDialog() {
      this.setCheckedButton();
      this.dialog.open(DialogElementsExampleDialogComponent);
    }

    ngOnInit(): void {
      this.sync();
      let companies_id=localStorage.getItem("COMPANY_SELECTED_ID");
      let branchesName=localStorage.getItem("BRANCH_SELECTED_NAME");
      let branchesLocation=localStorage.getItem("BRANCH_LOCATION");
      let roles_id=localStorage.getItem("AREA_SELECTED_ROLES_ID");
      let areas_id=localStorage.getItem("AREA_SELECTED_AREAS_ID");
      let companySelectedName=localStorage.getItem("COMPANY_SELECTED_NAME");
      console.log("company recuperada es ");
      console.log(companySelectedName)
      let role_name=localStorage.getItem("ROLES_NAME");
      console.log('role_name');
      if(companySelectedName&&branchesName&&role_name){
        this.companyName=companySelectedName;
        this.branchName=branchesName;
        this.areaName=role_name;
      }else{
        console.log('no entro')
      }
      this.setCheckedButton();
  }

  setCheckedButton(){
    let branches_id=localStorage.getItem("BRANCH_SELECTED_ID");
    this.branchService.getOneBranch(branches_id).subscribe(
      (data: any) => {
        console.log("my branch es");
        console.log(data);
        if(data.is_open==1){
          localStorage.setItem("IS_OPEN","TRUE");
          this.isChecked=true;
        }else{
          localStorage.setItem("IS_OPEN","FALSE");
          this.isChecked=false;
        }
        console.log("IS_OPEN")
        console.log(localStorage.getItem("IS_OPEN"))
      },
      (error) => {
        console.error(error);
      }
    );
  }


  login(username: string, password: string): void {
    this.loginService.login(username, password).subscribe(
      (response:LoginResponse) => {
        // Handle the response here
        console.log('Login response:', response);
        console.log('Id User:', response.user.id);
        if(response.token.length>5){
            console.log('Id User2:', response.user.id);
            localStorage.setItem("valor",response.token);
            localStorage.setItem("id",response.user.id.toString());
            localStorage.setItem("EMAIL",response.user.email.toString());
            localStorage.setItem("WORKSTATIONS_ID",response.user.workstations_id.toString());
            localStorage.setItem("ROLES_ID",response.user.roles_id.toString());
            localStorage.setItem("ROLES_NAME",response.user.roles_name);
            this.areaName=response.user.roles_name;
            localStorage.setItem("BRANCH_SELECTED_ID",response.branch.id.toString());
            localStorage.setItem("BRANCH_SELECTED_NAME",response.branch.name);
            localStorage.setItem("AREAS_ID",response.user.areas_id.toString());
            if(response.branch.is_open==1){
              localStorage.setItem("IS_OPEN","TRUE");
            }else{
              localStorage.setItem("IS_OPEN","FALSE");
            }
            localStorage.setItem("BRANCH_SELECTED_IS_OPEN",response.branch.is_open.toString());
            localStorage.setItem("BRANCH_SELECTED_LOCATION",response.branch.location);
            //localStorage.setItem("ROLE_SELECTED_ID",response.user.roles_id.toString());
            localStorage.setItem("COMPANY_SELECTED_ID",response.branch.companies_id.toString());
            this.getOneCompany(response.branch.companies_id);
            //this.getOneRole(response.user.roles_id);
            this.loginvisibility=false;
            this.branchName=response.branch.name;
            //this.getOneArea(response.branch.id,response.user.roles_id);
            console.log('Id User3:', response.user.id);

        }else{
            localStorage.clear();
        }
      },
      (error) => {
        console.error('Login error:', error);
      }
    );
  }
    
    submit(){
      console.log("user name is " + this.username)
      this.login(this.username,this.password)
      }
      
      getOneCompany(id:number){
        this.companyService.getOneCompany(id).subscribe(
          (data: any) => {
            console.log("my company es");
            console.log(data);
            localStorage.setItem("COMPANY_SELECTED_NAME",data.comercial_name);
            this.companyName=data.comercial_name
          },
          (error) => {
            console.error(error);
          }
        );
    }

    /*
    //get area name
    getOneRole(id:number){
      this.userService.getOneRole(id).subscribe(
        (data: any) => {
          console.log('data role')
          console.log(data)
          localStorage.setItem("AREA_SELECTED_ROLE_NAME",data.name);
          this.areaName=data.name
        },
        (error) => {
          console.error(error);
        }
      );
    }
    
    //get area id
    getOneArea(branchId:number,roleId:number){
      console.log('Id User3');
      this.areaService.getOneArea(branchId,roleId).subscribe(
        (data: any) => {
          console.log('getOneArea()')
          console.log(data)
          //localStorage.setItem("AREA_SELECTED_ROLE_NAME",data.name);
          localStorage.setItem("AREA_SELECTED_AREAS_ID",data.id);
          //this.areaName=data.name
          this.sync()
        },
        (error) => {
          console.error(error);
        }
      );
    }
    */

    listPendingSHifts(branches_id:any,areas_id:any){
      console.log(' branch: '+branches_id+' area: '+areas_id )
      this.myShiftService.getShifts(branches_id,areas_id).subscribe(
      //this.myShiftService.getShifts('1','1','1').subscribe(
        (data: Shift[]) => {
          this.shifts = data;
          this.turnosAdelante=this.shifts.length
          console.log("shifts")
          console.log(data)
        },
        (error) => {
          console.error(error);
        }
      );
    }

    next(id:number){
        this.myShiftService.markOneAsDispatched(id,localStorage.getItem("WORKSTATIONS_ID")).subscribe(
          (data: Shift[]) => {
            this.shifts = data;
            this.turnosAdelante=this.shifts.length
            console.log("shifts")
            console.log(data)
            this.sync();
          },
          (error) => {
            console.error(error);
          }
        );
    }

    sync(){
      if(localStorage.hasOwnProperty('valor')){
        this.loginvisibility=false;
        this.listPendingSHifts(localStorage.getItem("BRANCH_SELECTED_ID"),localStorage.getItem("AREAS_ID"))
      }else{
        console.log('No se pudo sincronizar')
      }
    }

    openPoliceChannel(){
      window.location.href ="https://demo1.vinoycucina.com/web#action=123&cids=1&menu_id=81&active_id=discuss.channel_1";
    }
    
    logout(){
      console.log('logout')
      localStorage.clear();
      window.location.reload();
    }

}