import { Component } from '@angular/core';
import { OnInit } from '@angular/core';

@Component({
  selector: 'app-my-shift',
  templateUrl: './my-shift.component.html',
  styleUrls: ['./my-shift.component.scss']
})
export class MyShiftComponent implements OnInit{

  companySelectedOfficialName:string="";
  companySelectedCommercialName:string="";

  ngOnInit(): void { 
    let idCompany=localStorage.getItem("COMPANY_SELECTED_ID");
    if(idCompany){}
  }
  
}
