import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Company } from './company.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {

  private apiUrlGet = environment.apiHost+'companies';
  private apiUrlPost = environment.apiHost+'companies';

  constructor(private http: HttpClient) {}

  getCompanies(): Observable<Company[]> {
    console.log('company-service.ts')
    console.log(this.apiUrlGet)
    return this.http.get<Company[]>(this.apiUrlGet);
  }

  getOneCompany(id:number): Observable<Company[]> {
    return this.http.get<Company[]>(this.apiUrlGet+'/'+id);
  }

  postOneBranch(companies_id:string,internalName: string, name: string, email:string, phone:string): Observable<any> {
    const body = { companies_id:companies_id,departments_id:5,internal_name:internalName,name:name,email:email,phone:phone };
    return this.http.post<any>(this.apiUrlPost, body);
  }

}
