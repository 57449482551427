import { Component, OnInit } from '@angular/core';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormsModule} from '@angular/forms';
import { LoginService } from '../login.service';
import { CompanyService } from '../company.service';
import { Company, LoginResponse } from '../login-response.interface';
import { CommonModule } from '@angular/common';  
import { MatButtonModule } from '@angular/material/button';
import { UserService } from '../user.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  standalone: true,
  imports: [FormsModule, MatFormFieldModule, MatInputModule,CommonModule,MatButtonModule]
})
export class LoginComponent implements OnInit{

  //users: User[] = [];
  companies: Company[] = [];
  //loginvisibility=true;
  constructor(private loginService: LoginService, private companyService:CompanyService,private userService:UserService) {}

  ngOnInit(): void { 
    /*
    if(localStorage.hasOwnProperty('valor')){
      this.loginvisibility=false;
    }
    */
    
  }

  login(username: string, password: string): void {
    this.loginService.login(username, password).subscribe(
      (response:LoginResponse) => {
        // Handle the response here
        console.log('Login response:', response);
        console.log('Id User:', response.user.id);
        if(response.token.length>5){
            localStorage.setItem("valor",response.token);
            localStorage.setItem("id",response.user.id.toString());
            localStorage.setItem("BRANCH_SELECTED_ID",response.branch.id.toString());
            localStorage.setItem("BRANCH_SELECTED_NAME",response.branch.name);
            localStorage.setItem("BRANCH_SELECTED_LOCATION",response.branch.location);
            localStorage.setItem("AREA_SELECTED_ROLES_ID",response.user.roles_id.toString());
            localStorage.setItem("AREA_SELECTED_AREAS_ID",response.user.roles_id.toString());
            localStorage.setItem("COMPANY_SELECTED_ID",response.branch.companies_id.toString());
            //localStorage.setItem("COMPANY_NAME",response.company.comercial_name);
            //get company name
            this.getOneCompany(response.branch.companies_id)
            this.getOneRole(response.user.roles_id)
            //localStorage.setItem("id",response.user.id)
            
            //localStorage.setItem("companies_id",response.user.company_id.toString());
            //localStorage.setItem("id",response.)
            //window.location.reload();

        }else{
            localStorage.clear();
        }
      },
      (error) => {
        // Handle errors
        console.error('Login error:', error);
      }
    );
  }

    username : string ="facturaelectronicaintouch@gmail.com";
    password : string ="123456";
    show: boolean= false;
    submit(){
    console.log("user name is " + this.username)
    this.login(this.username,this.password)
    //this.clear();
    }
    
    getOneCompany(id:number){
      this.companyService.getOneCompany(id).subscribe(
        (data: any) => {
          console.log("my company es");
          console.log(data);
          localStorage.setItem("COMPANY_SELECTED_NAME",data.comercial_name);
        },
        (error) => {
          console.error(error);
        }
      );
    }

    getOneRole(id:number){
      this.userService.getOneRole(id).subscribe(
        (data: any) => {
          console.log('data role')
          console.log(data)
          localStorage.setItem("AREA_SELECTED_ROLE_NAME",data.name);
        },
        (error) => {
          console.error(error);
        }
      );
    }
}


/*
import {Component} from '@angular/core';

@Component({
selector: 'login-demo',
styleUrls: ['demo.login.component.css'],
templateUrl: 'demo.login.component.html',
})
export class LoginFormDemo {
username : string ="";
password : string ="";
show: boolean= false;
submit(){
console.log("user name is " + this.username)
this.clear();
}
clear(){
this.username ="";
this.password = "";
this.show = true;
}
}
*/
