import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoginResponse } from './login-response.interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
  //private apiUrl = 'https://starappstechnologies.com/fel/auth/public/api/clients-by-company/3'; // Replace with your actual API endpoint
  //private apiUrl = 'http://localhost/fel/auth/public/api/login?email=facturaelectronicaintouch@gmail.com&password=123456';
  private apiUrl = environment.apiHost+'login';
  //private apiUrl2 = 'http://localhost/fel/auth/public/api/get-my-';

  constructor(private http: HttpClient) {}

  /*
  getUsers(): Observable<User[]> {
    return this.http.get<User[]>(this.apiUrl);
  }
  */

  login(username: string, password: string): Observable<any> {

    const body = { email: username, password: password };
    console.log('login-service.ts')
    console.log(this.apiUrl)
    return this.http.post<LoginResponse>(this.apiUrl, body);
  }
}
