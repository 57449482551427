import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatDialog,
  MatDialogActions,
  MatDialogClose,
  MatDialogContent,
  MatDialogTitle,
} from '@angular/material/dialog';
import {MatDialogModule} from '@angular/material/dialog';
import {MatButtonModule} from '@angular/material/button';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormsModule} from '@angular/forms';
import { BranchService } from '../branch.service';
import { LoginResponse } from '../login-response.interface';


@Component({
  selector: 'app-dialog-elements-example-dialog',
  standalone: true,
  //imports: [CommonModule,MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose, MatButtonModule],
  imports: [CommonModule,MatButtonModule,MatDialogModule,
    MatButtonModule,MatInputModule,MatFormFieldModule,FormsModule
  ],
  templateUrl: './dialog-elements-example-dialog.component.html',
  styleUrls: ['./dialog-elements-example-dialog.component.scss']
})
export class DialogElementsExampleDialogComponent implements OnInit{
    
    areaRubro:string=""
    abierto=false;
    
    
    constructor(private branchService: BranchService, public dialog: MatDialog,public matDialogTitle:MatDialogTitle,public matDialogContent:MatDialogContent,public matDialogActions:MatDialogActions, public matDialogClose:MatDialogClose) {}
    
    ngOnInit(): void {
      this.branchService.getOneBranch(localStorage.getItem("BRANCH_SELECTED_ID")).subscribe(
        (data: any) => {
          console.log("my branch es");
          console.log(data);
          if(data.is_open==1){
            localStorage.setItem("IS_OPEN","TRUE");
            this.abierto=true;
          }else{
            localStorage.setItem("IS_OPEN","FALSE");
            this.abierto=false;
          }
          let branchIsOpen=localStorage.getItem("IS_OPEN");
          console.log("IS_OPEN")
          console.log(branchIsOpen)
          
        },
        (error) => {
          console.error(error);
        }
      );
    }

    /*
    setRubro(rubro:string){
      this.areaRubro=rubro;
      //localStorage.setItem("RUBRO_SELECTED",rubro);
      //localStorage.setItem("VISIBILITY","TRUE");
    }
    */
    //username : string ="facturaelectronicaintouch@gmail.com";
    //password : string ="123456";

    username : any =localStorage.getItem("EMAIL");
    password : any ="";
    

    submit(){
      console.log('disparado');
      let branches_id=localStorage.getItem("BRANCH_SELECTED_ID");
      //console.log(branches_id);
      let branchIsOpen=localStorage.getItem("IS_OPEN");
      console.log("IS_OPEN")
      console.log(branchIsOpen)
      if(branchIsOpen==="TRUE"){
          console.log('disparado1')
          this.branchService.markAsClosed(branches_id,this.username,this.password).subscribe(
            (data: any) => {
              console.log("la sucursal cerrada es:");
              console.log(data);
              if(data.is_open==1){
                localStorage.setItem("IS_OPEN","TRUE");
              }else{
                localStorage.setItem("IS_OPEN","FALSE");
              }
              window.location.reload();
            },
            (error) => {
              console.error(error);
            }
          );
      }else{
        this.branchService.markAsOpen(branches_id,this.username,this.password).subscribe(

          (data: any) => {
            console.log("la sucursal abierta es:");
            console.log(data);
            if(data.is_open==1){
              localStorage.setItem("IS_OPEN","TRUE");
            }else{
              localStorage.setItem("IS_OPEN","FALSE");
            }
            window.location.reload();
          },
          (error) => {
            console.error(error);
          }
        );
          console.log('disparado2')
          
      }

      /*
      console.log("user name is " + this.username)
      this.loginService.login(this.username, this.password).subscribe(
        (response:LoginResponse) => {
          // Handle the response here
          console.log('Login response:', response);
          console.log('Id User:', response.user.id);
          if(response.token.length>5){
          }else{
              localStorage.clear();
          }
        },
        (error) => {
          console.error('Login error:', error);
        }
      );
      */

    }
    
}
