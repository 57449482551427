import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from './user.interface';
import {environment} from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  //private apiUrl = 'http://localhost/scheduler-php/public/api/login';
  private apiUrl = environment.apiHost+'login';

  constructor(private http: HttpClient) {}

  /*
  getUsers(): Observable<User[]> {
    return this.http.get<User[]>(this.apiUrlGet);
  }
  */

  login(username: string, password: string): Observable<any> {

    const body = { email: username, password: password };
    console.log('user-service.ts');
    console.log(this.apiUrl);
    return this.http.post<any>(this.apiUrl, body);
  }

  getOneRole(id:number): Observable<any> {
    console.log('user-service.ts');
    console.log(environment.apiHost+'role/'+id);
    return this.http.get<any>(environment.apiHost+'role/'+id);
  }
}
