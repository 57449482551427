import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';  
import { OnInit } from '@angular/core';
import {MatInputModule} from '@angular/material/input';
import {MatFormFieldModule} from '@angular/material/form-field';
import {FormsModule} from '@angular/forms';
import { UserService } from '../user.service';
import { BranchService } from '../branch.service';
import { CompanyService } from '../company.service';
import { User } from '../user.interface';
import { Branch } from './branch.interface';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoginService } from '../login.service';
import { LoginResponse } from '../login-response.interface';
import { MatButtonModule } from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';

@Component({
  selector: 'app-branches',
  templateUrl: './branches.component.html',
  styleUrls: ['./branches.component.scss'],
  standalone: true,
  imports: [FormsModule, MatFormFieldModule, MatInputModule,CommonModule,MatButtonModule,MatIconModule]
})
export class BranchesComponent implements OnInit {

  //public appRoutes = routes;
  users: User[] = [];
  branches: Branch[] = [];
  branchesFiltered: Branch[] = [];
  name:string="";
  internal_name:string="";
  searcher:string="";

  constructor(private userService: UserService,private companyService: CompanyService,private branchService: BranchService, private http:HttpClient) {}

  ngOnInit(): void { 
  
    /*
    this.userService.getUsers().subscribe(
        (data: User[]) => {
          this.users = data;
        },
        (error) => {
          console.error(error);
        }
    );
    */

    /*    
    this.branchService.getBranches().subscribe(
      (data: Branch[]) => {
        this.branches = data;
          //Here you got the filtered array.
          //const strs = ['valval', 'bal', 'gal', 'dalval'];
          //const result = strs.filter(s => s.includes('val'));
      },
      (error) => {
        console.error(error);
      }
    );
    */
    
  }

  /*
    create2(): void {
      this.createBranch().subscribe(
        (response) => {
          // Handle the response here
          console.log('Branch response:', response);
        },
        (error) => {
          // Handle errors
          console.error('Branch error:', error);
        }
      );
    }

    createBranch(): Observable<any> {
        const body = { companies_id:3, departments_id:5, internal_name:"NA", internal_code:0,name:"sanfdo2", email:"snf2@gm.com",phone:"301245", city_code:"CTG", location:"calle", isMain:0 };
        return this.http.post<any>(this.apiUrlPostCompany, body);
    }
    */

    filtrar(){
      let idCompany=localStorage.getItem("COMPANY_SELECTED");
      if(idCompany){
        this.branchService.getBranches(idCompany).subscribe((res: any) => {
          let filteredArr = res.filter((data: { name: string; }) => data.name.toUpperCase().includes(this.searcher.toUpperCase()));
          console.log("ruben blades");
          console.log(filteredArr);
          this.branchesFiltered=filteredArr;
          //Here you got the filtered array.
        });
      }
    }

    seleccionar(id:number){
      localStorage.setItem("COMPANY_SELECTED",id.toString())
    }

}